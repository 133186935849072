export const superEvent = {
  "@context": "https://schema.org",
  "@type": "Event",
  name: "Y Oslo Digital",
  description:
    "Y is a conference where you learn to make better products and services — for people and for the planet.",
  location: {
    "@type": "VirtualLocation",
    name: "Streamed online by invite",
    url: "https://www.y-oslo.com/",
  },
  eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
  eventStatus: "https://schema.org/EventMovedOnline",
  offers: {
    "@type": "Offer",
    url: "https://www.y-oslo.com/tickets",
    price: "800",
    priceCurrency: "NOK",
    availability: "https://schema.org/OnlineOnly",
    validFrom: new Date("2020-03-04"),
  },
  startDate: new Date("2020-10-29"),
  endDate: new Date("2020-10-30"),
  performer: [
    {
      "@type": "Organization",
      name: "Y Oslo",
      url: "https://www.y-oslo.com/",
    },
    {
      "@type": "Organization",
      name: "Netlife Design",
      url: "https://www.netlife.com/",
    },
    {
      "@type": "Organization",
      name: "Posten",
      url: "https://www.posten.no/",
    },
  ],
  organizer: {
    "@type": "Organization",
    name: "Netlife Design",
    url: "https://www.netlife.com/",
  },
  url: "https://www.y-oslo.com/",
  image: "https://www.y-oslo.com/icons/icon-512x512.png",
}
