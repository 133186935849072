import React from "react";
import { graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";

// IMport utils
import findTalkDateAndTime from "../utils/dateUtil";
import { getBlockContent, getBlockImage } from "../utils/helpers";
import { superEvent } from "../utils/superEvent";

// Layout
import Layout from "../components/layout/Layout";
import SpeakerHero from "../components/layout/SpeakerHero";

// Containers
import RenderRichContent from "../components/containers/RenderRichContent";

// Primitives
import EmojiIcon from "../components/primitives/EmojiIcon";
import Grid from "../components/primitives/Grid";
// import Button from "../components/primitives/Button";

const Talk = ({ data }) => {
  const { sanityTalk = {} } = data;

  const {
    duration,
    time,
    title,
    sceneRef,
    lead,
    _rawDescription,
    foredragsholdere,
    metaInfo,
    slug,
    youtubeUrl,
  } = sanityTalk;

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader,
    description:
      metaInfo?.description || lead || getBlockContent([_rawDescription]),
    fullSlug: `talk/${slug?.current}`,
    image:
      metaInfo?.image ||
      getBlockImage([_rawDescription])?.image ||
      foredragsholdere?.[0]?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage([_rawDescription])?.alt,
    video: youtubeUrl,
  };

  const pageSchemas = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: metaInfo?.header || title || "",
    description:
      metaInfo?.description || lead || getBlockContent([_rawDescription]) || "",
    location: {
      "@type": "VirtualLocation",
      name: "Streamed online by invite",
      url: "https://www.y-oslo.com/",
    },
    eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
    eventStatus: "https://schema.org/EventMovedOnline",
    offers: {
      "@type": "Offer",
      url: "https://www.y-oslo.com/tickets",
      price: "800",
      priceCurrency: "NOK",
      availability: sanityTalk?.full
        ? "https://schema.org/SoldOut"
        : "https://schema.org/OnlineOnly",
      validFrom: new Date("2020-03-04"),
    },
    startDate: time,
    endDate:
      time && duration
        ? new Date(new Date(time).getTime() + duration * 60000)
        : time,
    organizer: {
      "@type": "Organization",
      name: "Netlife Design",
      url: "https://www.netlife.com/",
    },
    duration: `PT${duration}M`,
    url: `https://www.y-oslo.com/talk/${slug?.current}`,
    image: {
      "@type": "ImageObject",
      contentUrl:
        metaInfo?.image?.asset?.url ||
        getBlockImage([_rawDescription])?.image?.asset?.url ||
        foredragsholdere?.[0]?.image?.asset?.url ||
        "https://www.y-oslo.com/icons/icon-512x512.png",
      url:
        metaInfo?.image?.asset?.url ||
        getBlockImage([_rawDescription])?.image?.asset?.url ||
        foredragsholdere?.[0]?.image?.asset?.url ||
        "https://www.y-oslo.com/icons/icon-512x512.png",
    },
    performer:
      foredragsholdere.map(person => {
        return {
          "@type": "Person",
          name: person?.name ?? "",
          url: `https://www.y-oslo.com/speaker/${person?.slug?.current}`,
          image: person?.image?.asset?.url ?? "",
        };
      }) ?? "",
    superEvent: superEvent,
  };

  return (
    <Layout metaInfo={metaStructure} pageSchemas={pageSchemas}>
      {/* Name of the talk, time, speaker image, speaker name */}
      {foredragsholdere.length === 1 && (
        <SpeakerHero
          person={foredragsholdere[0]}
          talktitle={true}
          talksData={sanityTalk}
          title={title}
          heroType="talk"
          details={true}
          time={findTalkDateAndTime(time, duration)}
          stage={sceneRef?.title || ""}
        />
      )}
      {/* <p className="text-2xl">
        {time} + {duration}min
      </p>
      {sceneRef && <p className="text-2xl">{sceneRef?.title || ""}</p>}
      <p className="text-2xl">{title}</p> */}

      {/* Talk overview */}
      <Grid className="mb-16">
        <div className="col-span-12 md:col-span-7">
          {/* Not sure what this is: */}
          {lead}

          {/* Summary text */}
          <RenderRichContent content={_rawDescription} />

          {/* Centered ticket button */}
          {/* <div className="flex justify-center mb-16">
            <Button color="green" size="lg" href="/y-ticket-info" arrows>
              Get your ticket
            </Button>
          </div> */}

          {/* Speakers */}
          {foredragsholdere.map(({ name, _rawBio }) => (
            <div className="mb-16" key={uuidv4()}>
              <h2
                className="
                  block
                  text-lg uppercase
                "
              >
                About {name}
                <EmojiIcon name="leadership" animated className="ml-1" />
              </h2>

              <RenderRichContent content={_rawBio} />
              {/* 
                  <div className="flex max-w-xl mx-auto items-center mb-3">
                  <div className="w-2/6">
                  <img
                    className="h-24 md:h-48 object-cover"
                    src={image.asset.url}
                    alt={name}
                  />
                </div>
                <div className="w-4/6 ml-3">
                  <Accordion
                    color="green"
                    text="black"
                    hover="white"
                    focus="black"
                    arrowSize="xl"
                    width="auto"
                  >
                    <div data-header-speaker={name}>
                      <div
                        className="
                        bg-green
                        p-4 mt-3
                        max-w-sm
                        "
                      >
                        <p className="netlife-sans">{shoutOut}</p>
                        <br />

                        <Link className="underline" to={`/speaker/${slug.current}`}>
                          Read more about {name}
                        </Link>
                      </div>
                    </div>
                  </Accordion>
                </div> 
              </div> 

              */}
            </div>
          ))}
        </div>
      </Grid>
    </Layout>
  );
};
export default Talk;
export const query = graphql`
  query($id: String!) {
    sanityTalk(id: { eq: $id }) {
      title
      _rawDescription(resolveReferences: { maxDepth: 10 })
      time
      lead
      duration
      sceneRef {
        title
      }
      slug {
        current
      }
      youtubeUrl
      foredragsholdere {
        stilling
        language
        company
        image {
          asset {
            url
          }
        }
        slug {
          current
        }
        name
        shoutOut
        _rawBio(resolveReferences: { maxDepth: 10 })
      }
      metaInfo {
        description
        header
        someHeader
        image {
          asset {
            url
          }
        }
        imageAlt
      }
    }
  }
`;
